<template>
  <div>
    <div v-if="isAllowPage" class="pt-5 pb-3 w-50 m-auto">
      <security-banner></security-banner>
    </div>

    <div v-else class="service-times-presentations template-2">
      <header class="page-header container mx-lg">
        <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
      </header>
      <!-- / Page Header -->
      <page-body class="mx-lg">
        <section class="section-1">
          <div class="bg-block p-4">
            <header class="mb-2">
              <h4>{{ translations.tcPaymentCenter }}</h4>
            </header>
            <div class="d-flex row ml-1 mb-2 details2 ">
              <h5>
                {{
                  paymentInfo.isAutoPaySetup && !paymentInfo.isPayDuesLinkSupposeToBeVisible
                    ? `${translations.tcMembershipDuesAutopay} ${paymentFrequency}`
                    : 'MEMBERSHIP DUES'
                }}
              </h5>
            </div>
            <template>
              <div class="d-flex row mb-4 details2">
                <div class="col">
                  <div class="detail">
                    <!--- PAY Now-->
                    <b-button v-if="showPayNow" variant="primary" @click="handleOneTimePaymentLinkClick"
                      class="mb-1">Pay Now</b-button>
                    <!-- EDIT PAYMENT -->
                    <b-button variant="primary" @click="handleAutoPayClick" class="mb-1"
                      v-if="showAdjustAutopayPaymentInformation">
                      {{ translations.tcEditPayment }}
                    </b-button>
                    <!-- TURN OFF AUTO-PAY -->
                    <b-button variant="primary" class="mb-1" v-if="showCancelAutopay"
                      @click="userMembershipAutoPayOffHandler">
                      TURN OFF AUTO-PAY</b-button>
                    <!-- SET UP AUTOPAY -->
                    <h4 v-if="showSignUpAutopay">{{ translations.tcNoAutoPayAssociatedWithAccount }}</h4>
                    <b-button v-if="showSignUpAutopay" variant="primary" @click="handleAutoPayClick"
                      class="mb-1 details2">Set Up Auto Payment
                    </b-button>
                    <!-- ADJUST INSTALLMENT -->
                    <b-button v-if="showAdjustInstallmentPaymentInformation" variant="primary-reverse"
                      @click="handleOneTimeRenewalMonthlyLinkClick" class="mb-1">
                      {{ paymentInfo.withdrawDate > 0 ? 'Adjust ' : '' }} Monthly Installments</b-button>
                    <!-- SET UP INSTALLMENT -->
                    <b-button v-if="showSignUpInstallments" variant="primary-reverse"
                      @click="handleSetUpRenewalMonthlyLinkClick" class="mb-1">
                      Setup Monthly Installments</b-button>
                  </div>
                </div>
                <div class="col">
                  <div class="detail post-message" v-if="isDuesSeason && paymentInfo.paidFlag">
                    <h4>{{ translations.tcDuesArePaid }}</h4>
                  </div>
                  <div class="detail post-message" v-if="!isDuesSeason">
                    <h4>{{ translations.tcOutOfDuesSeason.replace('{0}', fiscalYear) }}</h4>
                  </div>
                  <div class="detail post-message" v-if="isPendingDropDate">
                    <h4>{{ translations.tcDuesAreInDefault.replace('{0}', paymentInfo.mbrPendingDropDate) }}</h4>
                  </div>
                  <div class="detail"
                    v-if="showAdjustAutopayPaymentInformation || showAdjustInstallmentPaymentInformation">
                    <h4>{{ translations.tcPersonalInformation }}</h4>
                    <template v-if="paymentInfo.expireDateField === '' || paymentInfo.expireDateField === null">
                      <b>{{ paymentInfo.addressee }}</b><br>
                      {{ translations.tcPaymentType }}: <b>{{ translations.tcACH }}</b><br>
                      {{ translations.tcAccountNumber }}: <b>{{ paymentInfo.lastFour }}</b><br>
                      {{ translations.tcRoutingNumber }}: <b
                        v-if="!!paymentInfo.routingNumberField && paymentInfo.routingNumberField.length > 4">*****{{
                          paymentInfo.routingNumberField.substring(paymentInfo.routingNumberField.length - 4,
                            paymentInfo.routingNumberField.length)
                        }}</b>
                    </template>
                    <template v-else>
                      <div style="width: 420px">
                        <table>
                          <colgroup>
                            <col style="width: 120px;" />
                            <col />
                          </colgroup>
                          <tr>
                            <td>Cardholder:</td>
                            <td><span class="paymentBold">{{ paymentInfo.addressee }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcPaymentType }}:</td>
                            <td><span class="paymentBold">{{ translations.tcCreditCard }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcAccountNumber }}:</td>
                            <td><span class="paymentBold">***********{{ paymentInfo.lastFour }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcBillingAddress }}:</td>
                            <td><span class="paymentBold">{{ paymentInfo.street }}</span>
                              <span class="paymentBold">{{ paymentInfo.city }}, {{ paymentInfo.state }} {{
                                paymentInfo.zip }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcExpirationDate }}:</td>
                            <td><span class="paymentBold">{{ paymentInfo.expireDateField }}</span></td>
                          </tr>
                        </table>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </section>
      </page-body>
      <page-footer class="mx-lg mt-5">
        <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
      </page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import constantData from '@/json/data.json'
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import SecurityBanner from '@/components/SecurityBanner'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'member-payment-center',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/mb/member-profile',
        location: ''
      },
      PaymentMethod_ACH_Guid: '3cde1523-989d-4264-bf2b-1f04e3752498',
      PaymentMethod_ACH_Name: 'ACH CyberSource',
      PaymentMethod_CCD_Guid: '8d85d8d1-d6ee-4e6e-a6e4-27212c8a1043',
      PaymentMethod_CCD_Name: 'Credit Card CyberSource',
      paymentFrequency: '',
      isLoginUserAutoSetup: null,
      // These flags are used to determine which buttons/messages to show
      // Refer to the Dues_Payment_Logic_v4.pdf in this folder
      // for more information on how these flags are used
      isDuesSeason: false,
      isPendingDropDate: false,
      isInstallmentPay: false,
      isAutoPay: false
    }
  },
  methods: {
    ...mapActions({
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadPaymentInformation: 'membership/loadPaymentInformation',
      setIsIndividualProfileLifeTime: 'membership/setIsIndividualProfileLifeTime',
      setLoadingStatus: 'menu/setLoadingStatus',
      turnOffMembershipAnnualAutoPay: 'membership/turnOffMembershipAnnualAutoPay'
    }),

    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getViewTranslations('member-payment-center'),
        await this.loadIndividualProfile(),
        await this.loadPaymentInformation(this.individualProfile.ind_key),
        await this.getComponentTranslations('back-jump'),
        await this.setIsIndividualProfileLifeTime(),
        (this.backJumpObj.location = this.individualProfile.formal_name)
      ]).then(results => {
        //check if they are a lifetime or veteran member
        //if they are, redirect them back to the membership landing page
        if (this.paymentInfo.renewalType === 'Lifetime' || this.paymentInfo.renewalType === 'Veteran') {
          this.$router.push({ path: '/programs/mb/member-profile' })
        }
        const componentTranslations = results[4]
        this.$set(this.translations, 'components', componentTranslations)
        this.paymentFrequency = this.paymentInfo.frequency || ''
        this.isLoginUserAutoSetup = Object.hasOwn(this.paymentInfo, 'isAutoPaySetup') && this.paymentInfo.isAutoPaySetup
        this.setPaymentInfoFlags()
        this.setLoadingStatus(false)
      })
    },
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
    async handleAutoPayClick() {
      this.$router.push({ path: '/programs/mb/autopay-form/link' })
    },
    async handleOneTimePaymentLinkClick() {
      this.$router.push({ path: '/programs/mb/member-dues/link' })
    },
    async handleOneTimeRenewalMonthlyLinkClick() {
      this.$router.push({ path: '/programs/mb/member-renewal/monthly/' })
    },
    async handleSetUpRenewalMonthlyLinkClick() {
      this.$router.push({ path: '/programs/mb/member-renewal/' })
    },
    async userMembershipAutoPayOffHandler() {
      const showSuccessMessage = async () => {
        return this.$swal({
          icon: 'success',
          text: 'You have successfully turned off annual auto-pay.'
        })
      }

      const showErrorMessage = async () => {
        this.$swal({
          icon: 'error',
          text: 'Something went wrong. Please try again.'
        })
      }

      this.$swal({
        html: `
        <div class="confirm-modal-inner">
          <img src="${require('@/assets/svgs/warning.svg')}" />
          <h3>Are you sure?</h3>
          <p>Do you want to turn off the annual automatic renewal for your payment method?</p>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(async result => {
        if (result.isConfirmed) {
          this.setLoadingStatus(true)
          const response = await this.turnOffMembershipAnnualAutoPay({
            cst_key: this.userIndKey
          })
          if (response) {
            showSuccessMessage().then(async () => {
              await this.pageLoad()
            })
          } else {
            showErrorMessage()
          }
          this.setLoadingStatus(false)
        }
      })
    },
    async setPaymentInfoFlags() {
      this.isDuesSeason = this.paymentInfo.isPayDuesLinkSupposeToBeVisible
      this.isPendingDropDate = this.paymentInfo.mbrPendingDropDate !== null
      this.isInstallmentPay = this.paymentInfo.mbrInstallpay
      this.isAutoPay = this.paymentInfo.isAutoPaySetup
    }
  },
  computed: {
    ...mapGetters({
      fiscalYear: 'membership/fiscalYear',
      individualProfile: 'membership/individualProfile',
      isIndividualProfileMemberLifetime: 'membership/isIndividualProfileMemberLifetime',
      paymentInfo: 'membership/paymentInfo',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey'
    }),
    isAllowPage() {
      return this.isIndividualProfileMemberLifetime
    },
    isPaymentFrequencyAnnual() {
      const ANNUAL_FREQ = 'Annually'
      return this.paymentFrequency === ANNUAL_FREQ
    },
    isLoginUserAutoOn() {
      return this.isLoginUserAutoSetup === true
    },
    showAdjustAutopayPaymentInformation() {
      return (
        (this.isDuesSeason && this.paymentInfo.paidFlag && this.isAutoPay) ||
        (!this.isDuesSeason && this.isAutoPay)
      )
    },
    showAdjustInstallmentPaymentInformation() {
      return (
        (!this.isDuesSeason && !this.isAutoPay && this.isInstallmentPay) ||
        (this.isDuesSeason && this.paymentInfo.paidFlag && this.isInstallmentPay) ||
        (this.isDuesSeason && !this.paymentInfo.paidFlag && this.isInstallmentPay)
      )
    },
    showPayNow() {
      return (
        (this.isDuesSeason && !this.paymentInfo.paidFlag && !this.isInstallmentPay)
      )
    },
    showSignUpAutopay() {
      return (
        (this.isDuesSeason && this.paymentInfo.paidFlag && !this.isAutoPay && !this.isInstallmentPay) ||
        (!this.isDuesSeason && !this.isAutoPay && !this.isInstallmentPay)
      )
    },
    showCancelAutopay() {
      return (
        (!this.isDuesSeason && this.isAutoPay)
      )
    },
    showSignUpInstallments() {
      return (
        (this.isDuesSeason && !this.paymentInfo.paidFlag && !this.isInstallmentPay)
      )
    }
  },

  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ path: '/programs/mb/membership/link' })
    }
    await this.pageLoad()
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    ToggleArrow: ToggleArrow,
    SecurityBanner
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.unenroll {
  color: #ee0000;
  width: 300px;
  font-weight: 600;
}

.paymentBold {
  font-weight: bold;
}

.btn-primary-reverse {
  background-color: #fff;
  color: #000;
  border-color: #000;
  border: 5px solid #003946 !important;
}

.btn.btn-primary-reverse:hover {
  background-color: #003946;
  color: #fff;
}

.post-message {
  width: 675px;
  margin-bottom: 20px;
}

.post-message h4 {
  color: #077c97;
  font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 35px;
  margin-bottom: 35px;
  text-transform: none;
}

.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details2 {
  margin-bottom: 50px;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  h5 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .detail {
    font-size: 15px;
    margin-bottom: 10px;

    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
    }

    strong {
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    td {
      vertical-align: top;
    }

    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }

    &.full-detail {
      display: block;
    }
  }
}
</style>
